<template>
	<div class="tw-w-full tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<HeaderSlot
		:title="project.name"
		:subTitle="project.description"
		:toBackButton="{path: project.isArchived? '/workspaces/archived' : '/workspaces'}"
		>
			<ButtonSlot
			@click="$refs.modal.showModale()"
			v-if="
				$hasRight('campaigns.createCampaignBtn') && 
					!project.isArchived &&
					campaigns !== false
			"
			>
				Créer une nouvelle campagne
			</ButtonSlot>
		</HeaderSlot>

		<CampaignCreationModale ref="modal"/>

		<div class="tw-flex tw-gap-[10px] tw-h-[50px]">
			<v-text-field
			class="tw-max-w-[250px]"
			append-icon="mdi-magnify"
			clearable
			hide-details
			:label="$t('search_campaign')"
			v-model="fn"
			/>

			<v-select
			class="tw-max-w-[200px]"
			v-model="ft"
			:items="listType"
			label="Filtrer par type"
			hide-details
			:item-text="(value) => value.name"
			:item-value="(value) => value.type"
			clearable
			/>

			<v-select
			class="tw-max-w-[400px]"
			v-model="fs"
			:items="listStatus"
			:label="$t('filter_status')"
			multiple
			hide-details
			:item-text="(value) => value[1].label"
			:item-value="(value) => value[0]"
			:disabled="ft === ''"
			clearable
			>
				<template v-slot:selection="{ item }">
					<v-chip
					close
					close-icon="mdi-close"
					@click:close="SET_FILTERSTATUS(fs.filter(value => value !== item[0]))"
					small
					>
						<span>{{ item[1].label }}</span>
					</v-chip>
				</template>
			</v-select>
			
			<v-checkbox
			label="Afficher les campagnes archivées"
			v-model="fa"
			/>
		</div>

		<div class="tw-grow tw-overflow-y-auto tw-flex tw-flex-col tw-pr-[5px] tw-gap-[10px]">
			<WaitingSlot
			class="tw-w-full tw-h-full"
			v-if="campaigns === false"
			/>

			<CampaignCard
			v-else
			v-for="campaign of filterCampaigns"
			class=""
			:campaign="campaign"
			/>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CampaignCard from "../../components/campaign/Campaign.card.vue";
import CampaignCreationModale from "../../components/campaign/CreateCampaign.modal.vue";
import {infoStatusAuscultation} from "../../utils/auscultation";
import {infoStatusCoringCA, infoStatusCoringCS} from "../../utils/coring";

export default {
	name: "Campaign",
	components: {
		CampaignCard,
		CampaignCreationModale
	},
	data(){
		return {
			listType: [
				{name: "Auscultation", type: "auscultation"},
				{name: "Carottage", type: "coring"},
			]
		};
	},
	computed: {
		...mapGetters("project", [
			"project", "campaigns", "filterCampaigns", "filterName", "filterStatus", "filterType", "filterArchived"
		]),

		fn: {
			get(){
				return this.filterName;
			},

			set(value){
				this.SET_FILTERNAME(value);
			}
		},
		fs: {
			get(){
				return this.filterStatus;
			},

			set(value){
				this.SET_FILTERSTATUS(value);
			}
		},
		ft: {
			get(){
				return this.filterType;
			},

			set(value){
				this.SET_FILTERTYPE(value);
			}
		},
		fa: {
			get(){
				return this.filterArchived;
			},

			set(value){
				this.SET_FILTERARCHIVED(value);
			}
		},
		listStatus(){
			if(this.ft === "auscultation") return Object.entries(infoStatusAuscultation).slice(0, -1);
			else if(this.ft === "coring") return Object.entries(infoStatusCoringCA).slice(0, -1);
			else return [];
		}
	},
	watch: {
	},
	methods: {
		...mapActions("project", ["initStore", "purgeStore"]),
		...mapMutations("project", [
			"SET_FILTERNAME", "SET_FILTERSTATUS", "SET_FILTERTYPE", "SET_FILTERARCHIVED"
		]),
	},
	async mounted(){
		await this.initStore(this.$route.params.id);
		
		let coring = this.campaigns.find(value => value.type === "coring");
		let auscultation = this.campaigns.find(value => value.type === "auscultation");
		if(coring === undefined && auscultation !== undefined) this.ft = "auscultation";
		if(coring !== undefined && auscultation === undefined) this.ft = "coring";
		
	},
	destroyed(){
		this.purgeStore();
	}
};
</script>

<style lang="scss">

</style>
