<template>
	<v-dialog
	v-model="show"
	persistent
	max-width="700px"
	scrollable
	>
		<v-card max-height="90vh">
			<div class="d-flex">
				<div class="flex-grow-1">
					<v-card-title> Créer une campagne </v-card-title>
				</div>

				<div class="d-flex align-center">
					<v-icon
					class="mr-4 pa-1 m_brown"
					@click="resetForm"
					title="Annuler la création de campagne"
					>
						mdi-close
					</v-icon>
				</div>
			</div>

			<v-card-text>
				<v-stepper
				flat
				tile
				elevation="0"
				v-model="e1"
				>
					<v-stepper-header>
						<v-stepper-step
						:complete="e1 > 1"
						step="1"
						class="primary-step"
						>
							Campagne
						</v-stepper-step>

						<v-divider/>

						<v-stepper-step
						:complete="e1 > 2"
						step="2"
						class="primary-step"
						>
							Organisation
						</v-stepper-step>

						<v-divider/>

						<v-stepper-step
						:complete="e1 > 3"
						step="3"
						class="primary-step"
						v-if="
							userOrganizationId === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3'
						"
						>
							Diffusion
						</v-stepper-step>

						<v-divider
						v-if="
							userOrganizationId === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3'
						"
						/>

						<v-stepper-step
						:step="
							userOrganizationId === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3'
								? 4
								: 3
						"
						class="primary-step"
						>
							Récapitulatif
						</v-stepper-step>
					</v-stepper-header>

					<v-stepper-items>
						<v-stepper-content step="1">
							<v-form
							ref="campaignForm"
							@submit.prevent="createCampaign()"
							class="form"
							>
								<v-container fluid>
									<h3>
										Type de campagne choisi : {{ campaignType || "null" }}
									</h3>

									<v-radio-group
									v-model="campaignType"
									@change="handleCampaignTypeChange"
									mandatory
									>
										<v-radio
										label="Auscultation"
										value="Auscultation"
										/>

										<v-radio
										label="Carottage"
										value="Carottage"
										/>
									</v-radio-group>
								</v-container>

								<v-select
								v-if="campaignType === 'Carottage'"
								:items="coringTypes"
								tabindex="1"
								item-text="name"
								item-value="value"
								label="Type de carottage*"
								outlined
								v-model="form.coringType"
								dense
								:validate-on-blur="true"
								/>

								<div v-if="campaignType === 'Carottage'">
									<h4>Préfixe(s) du nom des carottes :</h4>

									<div
									class="pb-2"
									v-if="0 < form.displayNamePrefixes.length"
									>
										<v-chip
										small
										v-for="(prefix, index) in form.displayNamePrefixes"
										:key="index"
										class="prefix-chip"
										close
										@click:close="removePrefix(index)"
										>
											{{ prefix.toUpperCase() }}
										</v-chip>
									</div>

									<div v-else>
										<p>
											Vous n'avez pas de préfixes définis sur cette campagne.
										</p>
									</div>

									<div class="new-prefix">
										<v-text-field
										label="Ajouter un préfixe"
										class="new-prefix-input"
										dense
										outlined
										:rules="[$rules.max255]"
										v-model="form.newPrefix"
										@keyup.enter="addPrefix"
										/>

										<ButtonSlot
										@click="addPrefix"
										_theme="light-gray"
										>
											Ajouter
										</ButtonSlot>
									</div>
								</div>

								<v-row>
									<v-col
									cols="12"
									md="6"
									>
										<v-text-field
										v-if="campaignType === 'Carottage'"
										tabindex="2"
										label="Épaisseur probable des carottes (en mm)"
										outlined
										clearable
										v-model="form.predictableThickness"
										:rules="[$rules.onlyNumbers]"
										dense
										:validate-on-blur="true"
										/>
									</v-col>

									<v-col
									cols="12"
									md="6"
									>
										<v-switch
										v-if="campaignType === 'Carottage'"
										v-model="form.isDirMethodology"
										class="methodology reverse-switch"
										>
											<template #label>
												{{ dirMethodologyLabel }}
											</template>
										</v-switch>
									</v-col>
								</v-row>

								<v-select
								v-if="campaignType === 'Auscultation'"
								:items="operatingMode"
								tabindex="1"
								item-text="name"
								item-value="value"
								label="Mode opératoire de relevé de dégradations (optionnel)"
								outlined
								clearable
								v-model="form.operatingMode"
								dense
								/>

								<v-text-field
								v-if="
									campaignType === 'Auscultation' &&
										form.operatingMode === 'not_standard'
								"
								tabindex="2"
								label="Commentaire sur le mode opératoire"
								v-model="form.operatingModeComment"
								outlined
								clearable
								dense
								/>

								<v-text-field
								tabindex="3"
								class="mt-1"
								counter="255"
								label="Nom de la campagne*"
								outlined
								clearable
								v-model="form.name"
								:rules="[$rules.required, $rules.min5Chars]"
								dense
								:validate-on-blur="true"
								/>

								<v-textarea
								tabindex="4"
								class="mt-1"
								label="Description"
								outlined
								clearable
								counter="500"
								v-model="form.globalComment"
								:rules="[$rules.max500]"
								dense
								no-resize
								rows="3"
								:validate-on-blur="true"
								/>

								<v-autocomplete
								tabindex="11"
								outlined
								label="Système géoréférentiel*"
								:items="projectingSystems"
								item-text="name"
								item-value="val"
								v-model="form.projectingSystem"
								placeholder="L93"
								dense
								:rules="[$rules.required]"
								/>

								<v-row>
									<v-col
									cols="12"
									md="6"
									>
										<v-menu
										transition="scale-transition"
										offset-y
										min-width="auto"
										:close-on-content-click="false"
										ref="menu"
										v-model="menu"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-combobox
												v-model="form.startDate"
												label="Date de début"
												tabindex="5"
												prepend-inner-icon="mdi-calendar"
												readonly
												v-bind="attrs"
												v-on="on"
												outlined
												clearable
												:rules="[
													$rules.earlierDate(form.startDate, form.endDate)
												]"
												dense
												append-icon=""
												>
													<template v-slot:selection>
														{{ moment(form.startDate).format("Do MMMM YYYY") }}
													</template>
												</v-combobox>
											</template>

											<v-date-picker
											v-model="form.startDate"
											no-title
											scrollable
											@change="menu = false"
											/>
										</v-menu>
									</v-col>

									<v-col
									cols="12"
									md="6"
									>
										<v-menu
										transition="scale-transition"
										offset-y
										min-width="auto"
										:close-on-content-click="false"
										ref="menu2"
										v-model="menu2"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-combobox
												v-model="form.endDate"
												label="Date de fin"
												tabindex="6"
												prepend-inner-icon="mdi-calendar"
												readonly
												v-bind="attrs"
												v-on="on"
												outlined
												clearable
												:rules="[
													$rules.laterDate(form.startDate, form.endDate)
												]"
												dense
												append-icon=""
												>
													<template v-slot:selection>
														{{ moment(form.endDate).format("Do MMMM YYYY") }}
													</template>
												</v-combobox>
											</template>

											<v-date-picker
											v-model="form.endDate"
											no-title
											scrollable
											@change="menu2 = false"
											/>
										</v-menu>
									</v-col>
								</v-row>

								<HighButtonSlot
								class="right_btn"
								@click="validateForm('campaignForm')"
								>
									Continuer
								</HighButtonSlot>
							</v-form>
						</v-stepper-content>

						<v-stepper-content step="2">
							<v-form
							ref="organizationForm"
							@submit.prevent="createCampaign()"
							class="form"
							>
								<v-row>
									<v-col
									cols="12"
									md="12"
									>
										<h3>
											{{
												project.customerSite? "Organisation client liée au projet : " + project.customerSite.organization.name : ""
											}}
										</h3>

										<br/>

										<h3>Sélection du client :</h3>

										<br/>

										<v-row>
											<v-col
											cols="8"
											sm="10"
											>
												<v-autocomplete
												:items="customers"
												item-text="name"
												item-value="id"
												tabindex="7"
												label="Client*"
												class="mt-1"
												outlined
												clearable
												v-model="form.customerId"
												@change="
													form.customerId &&
														getOrganizationNameById(
															form.customerId,
															customers
														)
												"
												prepend-inner-icon="mdi-account-search"
												:rules="[$rules.required]"
												dense
												:validate-on-blur="true"
												:loading="loadingCustomers"
												/>
											</v-col>

											<v-col
											cols="2"
											md="2"
											>
												<ButtonSlot
												class="right_btn mr-4 mt-1"
												@click="isCreateEditUserModalOpen = true"
												_theme="light-gray"
												_icon="mdi-plus"
												_icon-size="1.5rem"
												/>
											</v-col>
										</v-row>

										<h3 class="mt-3">
											Sélection de l'équipe projet interne :
										</h3>

										<br/>

										<v-autocomplete
										:items="studyManagers"
										item-text="name"
										tabindex="8"
										item-value="id"
										:label="
											form.coringType === 'CS'
												? 'Chargé d\'affaires référent'
												: 'Chargé d\'affaires référent*'
										"
										class="mt-1"
										outlined
										clearable
										v-model="form.studyManagerId"
										prepend-inner-icon="mdi-account-search"
										:rules="
											this.form.coringType !== 'CS'
												? [$rules.required]
												: [
													$rules.atLeastOneEngineerOrStudyManager(
														this.form.engineerId,
														this.form.studyManagerId
													)
												]
										"
										dense
										:validate-on-blur="true"
										:loading="loadingUsers"
										/>

										<v-autocomplete
										:items="engineers"
										item-text="name"
										tabindex="9"
										item-value="id"
										label="Ingénieur"
										outlined
										clearable
										v-model="form.engineerId"
										prepend-inner-icon="mdi-account-search"
										:rules="
											form.coringType === 'CS'
												? [
													$rules.atLeastOneEngineerOrStudyManager(
														this.form.engineerId,
														this.form.studyManagerId
													)
												]
												: []
										"
										dense
										:validate-on-blur="true"
										:loading="loadingUsers"
										/>

										<v-autocomplete
										v-if="campaignType === 'Carottage'"
										:items="technicians"
										item-text="name"
										tabindex="10"
										item-value="id"
										label="Technicien(s)*"
										outlined
										clearable
										v-model="form.technicianIds"
										prepend-inner-icon="mdi-account-search"
										:rules="[$rules.atLeastOneTechnician]"
										multiple
										small-chips
										dense
										:validate-on-blur="true"
										:loading="loadingUsers"
										/>

										<v-textarea
										v-if="campaignType === 'Carottage'"
										tabindex="11"
										label="Note pour le(s) technicien(s)"
										outlined
										clearable
										counter="500"
										no-resize
										rows="3"
										v-model="form.technicianNote"
										dense
										:rules="[$rules.max500]"
										:validate-on-blur="true"
										/>
									</v-col>
								</v-row>
							</v-form>

							<MediumButtonSlot @click="e1 = 1">
								Retour
							</MediumButtonSlot>

							<HighButtonSlot
							class="right_btn"
							@click="validateForm('organizationForm')"
							>
								Continuer
							</HighButtonSlot>
						</v-stepper-content>

						<v-stepper-content
						step="3"
						v-if="
							userOrganizationId === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3'
						"
						>
							<v-form
							ref="diffusionForm"
							@submit.prevent="createCampaign()"
							class="form"
							>
								<v-col
								cols="12"
								md="12"
								>
									<h3>
										Diffusion de la campagne - si vous souhaitez partager cette
										affaire avec d'autres agences
									</h3>

									<br/>

									<v-autocomplete
									label="Filtrer par agence(s)"
									tabindex="14"
									required
									outlined
									dense
									clearable
									:items="agencies"
									v-model="diffusionForm.agencyIds"
									@change="handleAgenciesAndSitesChange"
									item-text="name"
									item-value="id"
									prepend-inner-icon="mdi-account-search"
									multiple
									small-chips
									deletable-chips
									:loading="loadingAgencies"
									/>

									<v-autocomplete
									label="Filtrer par site(s)"
									tabindex="15"
									required
									outlined
									dense
									clearable
									:items="sites"
									v-model="diffusionForm.siteIds"
									@change="handleAgenciesAndSitesChange"
									item-text="name"
									item-value="id"
									prepend-inner-icon="mdi-account-search"
									multiple
									small-chips
									deletable-chips
									:loading="loadingSites"
									/>

									<v-autocomplete
									tabindex="16"
									label="Chargé(s) d'affaires"
									outlined
									dense
									clearable
									v-model="form.campaignMembers"
									:items="members"
									item-text="name"
									item-value="id"
									prepend-inner-icon="mdi-account-search"
									multiple
									small-chips
									deletable-chips
									/>
								</v-col>
							</v-form>

							<MediumButtonSlot @click="e1 = 2">
								Retour
							</MediumButtonSlot>

							<HighButtonSlot
							class="right_btn"
							@click="validateForm('diffusionForm')"
							>
								Continuer
							</HighButtonSlot>
						</v-stepper-content>

						<v-stepper-content
						:step="
							userOrganizationId === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3'
								? 4
								: 3
						"
						>
							<v-card
							class="mb-12"
							outlined
							>
								<v-card-title>Récapitulatif</v-card-title>

								<v-card-text>
									<p>
										<b>Type de campagne</b>

										<span class="m_brown">
											&nbsp; {{ campaignType }}
										</span>
									</p>

									<p v-if="campaignType === 'Carottage'">
										<b>Type de carottage</b>

										<span class="m_brown">
											&nbsp; {{ $t(form.coringType) }}</span>
									</p>

									<p
									v-if="
										campaignType === 'Auscultation' &&
											form.operatingMode != null
									"
									>
										<b>Mode opératoire</b>

										<span class="m_brown">
											&nbsp; {{ $t(form.operatingMode) }}</span>
									</p>

									<p
									v-if="
										campaignType === 'Auscultation' &&
											form.operatingModeComment !== null
									"
									>
										<b>Commentaire sur le mode opératoire</b>

										<span class="m_brown">
											&nbsp; {{ form.operatingModeComment }}
										</span>
									</p>

									<p
									v-if="
										campaignType === 'Carottage' &&
											form.displayNamePrefixes !== []
									"
									>
										<b>Préfixe du nom des carottes</b>

										<span class="m_brown">
											<v-chip
											v-for="(prefix, index) in form.displayNamePrefixes"
											:key="index"
											small
											class="prefix-chip"
											>
												{{ prefix }}
											</v-chip>
										</span>
									</p>

									<p
									v-if="
										campaignType === 'Carottage' &&
											form.predictableThickness !== null
									"
									>
										<b>Épaisseur probable des carottes</b>

										<span class="m_brown">
											&nbsp; {{ form.predictableThickness }}</span>
									</p>

									<p v-if="campaignType === 'Carottage'">
										<b>Méthodologie</b>

										<span class="m_brown">&nbsp;
											{{
												form.isDirMethodology === true ? "DIR" : "Normale"
											}}</span>
									</p>

									<p>
										<b>Nom de la campagne</b>

										<span class="m_brown">
											&nbsp; {{ form.name }}
										</span>
									</p>

									<p v-if="form.globalComment !== null">
										<b>Description</b>

										<span class="m_brown">
											&nbsp; {{ form.globalComment }}
										</span>
									</p>

									<p>
										<b>Système géoréférentiel</b>

										<span class="m_brown">
											&nbsp; {{ form.projectingSystem }}
										</span>
									</p>

									<p>
										<b>Client</b>

										<span class="m_brown">&nbsp;
											{{ getNameById(form.customerId, customers) }}</span>
									</p>

									<p v-if="form.studyManagerId !== null">
										<b>Chargé d'affaires référent</b>

										<span class="m_brown">&nbsp;{{
											getNameById(form.studyManagerId, studyManagers)
										}}</span>
									</p>

									<p v-if="form.engineerId !== null">
										<b>Ingénieur</b>

										<span class="m_brown">
											&nbsp;{{ getNameById(form.engineerId, engineers) }}
										</span>
									</p>

									<p v-if="campaignType === 'Carottage'">
										<b>Technicien</b>

										<span class="m_brown">
											&nbsp;{{ getTechniciansToString() }}
										</span>
									</p>

									<p
									v-if="
										campaignType === 'Carottage' &&
											form.technicianNote !== null
									"
									>
										<b>{{
											form.technicianIds.length > 1
												? "Note pour les techniciens"
												: "Note pour le technicien"
										}}</b>

										<span class="m_brown">
											&nbsp; {{ form.technicianNote }}
										</span>
									</p>

									<p v-if="form.campaignMembers.length > 0">
										<b>Chargé(s) d'affaires</b>

										<span class="m_brown">
											&nbsp; {{ getCampaignMembersToString() }}
										</span>
									</p>

									<p
									v-if="
										campaignType === 'Carottage' &&
											form.defaultCoreParams.structureType !== null
									"
									>
										<b>Type de structure par défaut</b>

										<span class="m_brown">&nbsp;
											{{
												getStructureTypeName(
													form.defaultCoreParams.structureType
												)
											}}</span>
									</p>

									<p
									v-if="
										campaignType === 'Carottage' &&
											form.defaultCoreParams.fullStructure !== null
									"
									>
										<b>Structure complète par défaut</b>

										<span class="m_brown">&nbsp;
											{{
												form.defaultCoreParams.fullStructure === true
													? "Oui"
													: "Non"
											}}</span>
									</p>

									<p
									v-if="
										campaignType === 'Carottage' &&
											form.defaultCoreParams.diameter !== null
									"
									>
										<b>Diamètre par défaut</b>

										<span class="m_brown">
											&nbsp; {{ form.defaultCoreParams.diameter }}
										</span>
									</p>

									<p class="mb-2"/>
								</v-card-text>
							</v-card>

							<MediumButtonSlot
							@click="
								e1 =
									userOrganizationId ===
									'6e42295a-a6e4-43b8-84d9-d37c5d9db7b3'
										? 3
										: 2
							"
							>
								Retour
							</MediumButtonSlot>

							<HighButtonSlot
							@click="createCampaign()"
							class="right_btn"
							type="submit"
							:_loading="loading"
							>
								Valider la création
							</HighButtonSlot>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</v-card-text>
		</v-card>

		<CreateEditUserModal
		@closePopin="isCreateEditUserModalOpen = false"
		:organization="project.customerSite.organization"
		v-if="isCreateEditUserModalOpen"
		@userCreated="addNewUserToData"
		:isUserEdit="false"
		/>
	</v-dialog>
</template>

<script>
import CreateEditUserModal from "@/components/entities/user/CreateEditUser.modal";
import {mapActions} from "vuex";
export default {
	name: "CampaignCreationModale",
	components: {
		CreateEditUserModal
	},
	props: {
		workspaceId: {
			type: String
		}
	},
	data(){
		return {
			show: false,
			e1: 1,
			campaignType: null,
			projectingSystems: [
				{
					name: "World Geodetic system 1984",
					val: "WGS84"
				},
				{
					name: "Lambert 93",
					val: "L93"
				},
				{
					name: "Conique conforme 42",
					val: "CC42"
				},
				{
					name: "Conique conforme 43",
					val: "CC43"
				},
				{
					name: "Conique conforme 44",
					val: "CC44"
				},
				{
					name: "Conique conforme 45",
					val: "CC45"
				},
				{
					name: "Conique conforme 46",
					val: "CC46"
				},
				{
					name: "Conique conforme 47",
					val: "CC47"
				},
				{
					name: "Conique conforme 48",
					val: "CC48"
				},
				{
					name: "Conique conforme 49",
					val: "CC49"
				},
				{
					name: "Conique conforme 50",
					val: "CC50"
				}
			],
			operatingMode: [
				{name: "M1", value: "M1"},
				{name: "M2", value: "M2"},
				{name: "M2+", value: "M2+"},
				{name: "M3", value: "M3"},
				{name: "M3+", value: "M3+"},
				{name: "M4", value: "M4"},
				{name: "M6", value: "M6"},
				{name: "M7", value: "M7"},
				{name: "Non standard", value: "not_standard"}
			],
			coringTypes: [
				{name: "Amiante", value: "CA"},
				{name: "Structurel", value: "CS"}
			],
			form: {
				name: "",
				globalComment: null,
				coringType: "CA",
				operatingMode: null,
				operatingModeComment: null,
				customerId: null,
				engineerId: null,
				studyManagerId: null,
				technicianIds: [],
				startDate: null,
				endDate: null,
				predictableThickness: null,
				technicianNote: null,
				displayNamePrefixes: [],
				newPrefix: null,
				defaultCoreParams: {
					structureType: null,
					fullStructure: null,
					diameter: null
				},
				campaignMembers: [],
				projectingSystem: "WGS84",
				isDirMethodology: false
			},
			diffusionForm: {
				agencyIds: [],
				siteIds: []
			},
			customers: [],
			engineers: [],
			studyManagers: [],
			technicians: [],
			agencies: null,
			sites: null,
			members: null,
			customerOrganizationName: "",
			stTypeField: [
				{id: 1, name: "Souple"},
				{id: 2, name: "Bitumineuse épaisse"},
				{id: 3, name: "Mixte"},
				{id: 4, name: "Rigide"}
			],
			diameterField: [
				50, 80, 100, 150
			],
			stCompleteField: [
				{bool: true, name: "Oui"},
				{bool: false, name: "Non"}
			],
			loading: false,
			menu: false,
			menu2: false,
			project: [],
			customerOrganizationId: null,
			userOrganizationId: null,
			allMembersMapped: [],
			isCreateEditUserModalOpen: false,
			loadingAgencies: true,
			loadingSites: true,
			loadingUsers: true,
			loadingCustomers: true
		};
	},
	computed: {
		dirMethodologyLabel(){
			const labelValue = this.form.isDirMethodology === true ? "Oui" : "Non";
			return "Méthodologie DIR : " + labelValue;
		}
	},
	watch: {
		e1(){
			if(2 === this.e1){
				this.diffusionForm.agencyIds = [];
				this.diffusionForm.siteIds = [];
				this.form.campaignMembers = [];
			}
		},
		show(){
			if(true === this.show && null === this.agencies && null === this.sites){
				this.getInformations();
			}
		}
	},
	methods: {
		...mapActions("project", ["initStore"]),

		getInformations(){
			this.getWorkspaceInformations().then(() => {
				this.$api.users
				.findByOrganization(this.userOrganizationId)
				.then(users => {
					this.engineers = users
					.filter(user => user.job === "engineer")
					.map(user => this.mapUserInformations(user))
					.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

					this.technicians = users
					.filter(user => user.job !== "engineer")
					.map(user => this.mapUserInformations(user))
					.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

					this.studyManagers = users
					.filter(
						user =>
							user.job === "study_manager" || user.job === "commercial"
					)
					.map(user => this.mapUserInformations(user))
					.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

					this.allMembersMapped = users
					.filter(
						user =>
							user.job === "study_manager" || user.job === "commercial"
					)
					.map(user => this.mapUserInformations(user, true))
					.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

					this.members = this.allMembersMapped;

					this.members = this.members.filter(
						member => member.id !== this.$store.state.user.current.userId
					);
					this.loadingUsers = false;
				});

				this.$api.users
				.findByOrganization(this.customerOrganizationId)
				.then(users => {
					this.customers = users
					.filter(
						user => user.roles.includes("ROLE_CUSTOMER_MAIN")
					)
					.map(user => this.mapUserInformations(user, false, true))
					.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
					this.loadingCustomers = false;
				});

				this.$api.agencies.findAll().then(agencies => {
					this.agencies = agencies;
					this.loadingAgencies = false;
				});
				if(this.$store.state.user.current.isInternal) this.$api.organizations
				.getAllSitesFromOrganization(this.userOrganizationId)
				.then(sites => {
					this.sites = sites;
					this.loadingSites = false;
				});
			});
		},
		addNewUserToData(user){
			if(user.sites.find(site => site.id === this.project.customerSite.id) !== undefined){
				this.customers.push(this.mapUserInformations(user));
			}
		},
		async getWorkspaceInformations(){
			this.project = await this.$api.workspaces.findById(this.$route.params.id);
			this.customerOrganizationId = this.project.customerSite.organization.id;
			this.userOrganizationId = this.project.site.organization.id;
		},
		// TODO : Adapter les deux prochaines méthodes pour les réunir en une
		getTechniciansToString(){
			// v for
			const finalTechnicians = [];
			this.form.technicianIds.forEach(id => {
				finalTechnicians.push(this.getNameById(id, this.technicians));
			});
			return finalTechnicians.toString().replace(",", ", ");
		},
		getCampaignMembersToString(){
			// v for
			const finalCampaignMembers = [];
			this.form.campaignMembers.forEach(id => {
				finalCampaignMembers.push(this.getNameById(id, this.members));
			});
			return finalCampaignMembers.toString().replace(",", ", ");
		},
		validateForm(ref){
			const formRef = this.$refs[ref];
			if(formRef.validate() === true){
				this.e1 += 1;
			}
		},
		showModale(){
			this.show = true;
		},
		mapUserInformations(
			user,
			displaySite = false,
			displayOrganization = false
		){
			let userEmail = " (" + user.email + ")";
			let siteInformation = "";

			if(displaySite === true){
				siteInformation = " - " + this.project.customerSite.name;
				userEmail = "";
			}

			let userInformations = {
				name:
          user.firstname + " " + user.lastname + userEmail + siteInformation,
				id: user.id
			};

			if(displayOrganization === true){
				userInformations = {
					...userInformations,
					organization: user.sites.find(site => site.organization.id === this.project.customerSite.organization.id).organization.name
				};
			}

			return userInformations;
		},
		createCampaign(){
			this.loading = true;
			this.form["workspaceId"] = this.$route.params.id;
			if(this.campaignType === "Auscultation"){
				this.$api.auscultationCampaigns
				.create(this.form)
				.then(campaign => {
					this.resetForm();
					this.initStore(this.$route.params.id);
				})
				.catch((e) => {
					this.loading = false;
				});
			}
			else {
				this.$api.coringCampaigns
				.create({...this.form})
				.then(campaign => {
					this.resetForm();
					this.initStore(this.$route.params.id);
				})
				.catch(() => (this.loading = false));
			}
		},
		getNameById(id, users){
			const user = users.find(user => user.id === id);

			if(typeof user !== "undefined"){
				return user.name || "//";
			}
		},
		getStructureTypeName(structureTypeId){
			const structureType = this.stTypeField.find(
				type => type.id === structureTypeId
			);
			return structureType.name;
		},
		async getOrganizationNameById(id, customers){
			const customer = customers.find(customer => customer.id === id);
			this.customerOrganizationName = customer.organization;
		},
		handleCampaignTypeChange(){
			if(this.campaignType === "Carottage"){
				this.form.operatingMode = null;
				this.form.operatingModeComment = null;
			}
			else if(this.campaignType === "Auscultation"){
				this.form.technicianIds = [];
				this.form.technicianNote = null;
				this.form.predictableThickness = null;
				this.form.defaultCoreParams.structureType = null;
				this.form.defaultCoreParams.fullStructure = null;
				this.form.defaultCoreParams.diameter = null;
			}
		},
		async resetForm(){
			this.show = false;
			this.e1 = 1;
			this.loading = false;
			await this.$refs["campaignForm"].reset();
			await this.$refs["organizationForm"].reset();
			if(this.$refs["diffusionForm"]) await this.$refs["diffusionForm"].reset();

			this.form.projectingSystem = "WGS84";
			this.form.displayNamePrefixes = [];
		},
		async handleAgenciesAndSitesChange(){
			this.loading = true;

			if(
				this.diffusionForm.agencyIds.length === 0 &&
        		this.diffusionForm.siteIds.length === 0
			){
				this.sites = await this.$api.organizations.getAllSitesFromOrganization(
					this.userOrganizationId
				);
				this.form.campaignMembers = [];
				this.members = this.allMembersMapped;
			}
			else if(
				this.diffusionForm.agencyIds.length > 0 &&
        this.diffusionForm.siteIds.length === 0
			){
				this.sites = await this.$api.sites.findByAgencies(
					this.diffusionForm.agencyIds
				);
				this.members = await this.$api.users.findByAgenciesAndJob(
					this.diffusionForm.agencyIds,
					"study_manager"
				);
				this.members = this.members
				.filter(user => user.id !== this.form.studyManagerId)
				.map(user => this.mapUserInformations(user, true));
				this.form.campaignMembers = [];
			}
			else if(
				this.diffusionForm.agencyIds.length === 0 &&
        this.diffusionForm.siteIds.length > 0
			){
				this.members = await this.$api.users.findBySitesAndJob(
					this.diffusionForm.siteIds,
					"study_manager"
				);
				this.members = this.members
				.filter(user => user.id !== this.form.studyManagerId)
				.map(user => this.mapUserInformations(user, true));
				this.form.campaignMembers = [];
			}

			this.members = this.members.filter(
				member => member.id !== this.$store.state.user.current.userId
			);
			this.loading = false;
		},
		addPrefix(){
			this.form.displayNamePrefixes.push(this.form.newPrefix.toUpperCase());
			this.form.newPrefix = null;
		},
		removePrefix(index){
			this.form.displayNamePrefixes.splice(index, 1);
		}
	},
	mounted(){
		this.$root.$on("closeCurrent", () => {
			this.show = false;
		});
	}
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.methodology {
  position: relative;
  bottom: 12px;
}

.right_btn {
  float: right;

  &:focus {
    background-color: red;
  }
}

.reverse-switch .v-input__slot {
  flex-direction: row-reverse;
  justify-content: space-around;

  .v-input--selection-controls__input {
    position: relative;
    right: 66px;
  }
}

.v-dialog {
  overflow-y: inherit !important;
}
.v-stepper__header {
  box-shadow: none !important;
}

.primary-step .primary {
  background-color: $brown !important;
  border-color: $brown !important;
}

.v-icon.v-icon::after {
  left: auto;
  transform: none !important;
}

.prefix-chip {
  margin: 4px;
}

.new-prefix {
  display: flex;
  justify-content: space-between;
}

.new-prefix-input {
  margin-right: 16px !important;
}

.new-prefix-input input {
  text-transform: uppercase;
}
</style>
