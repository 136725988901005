var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-justify-between tw-p-[10px] tw-items-start tw-cursor-pointer",attrs:{"campaign-card":""},on:{"click":_vm.redirectToCampaignView}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-[10px] tw-overflow-hidden tw-h-full tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-flex-row"},[_c('h2',{staticClass:"majFL tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden lineHeight tw-mr-[5px]"},[_vm._v(" "+_vm._s(_vm.campaign.name)+" ")]),(_vm.campaign.workspace.site.organization.id='6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' && _vm.campaign.type === 'coring')?_c('p',{staticClass:"business"},[_vm._v(" "+_vm._s(_vm.campaign.business)+" ")]):_vm._e()]),_c('div',{staticClass:"tw-flex tw-gap-[10px] tw-flex-wrap"},[(_vm.campaign.type === 'coring')?_c('v-chip',{attrs:{"small":""}},[_c('b',{staticClass:"majFL"},[_vm._v(" "+_vm._s(_vm.$t(_vm.campaign.coringType))+" ")])]):_c('v-chip',{attrs:{"small":""}},[_c('b',[_vm._v(_vm._s(_vm.$t(_vm.campaign.type)))])]),(
				_vm.campaign.type === 'auscultation' &&
					_vm.campaign.operatingMode !== 'not_standard' &&
					_vm.campaign.operatingMode !== null
			)?_c('v-chip',{attrs:{"small":""}},[_c('b',[_vm._v(_vm._s(_vm.$t(_vm.campaign.operatingMode)))])]):_vm._e(),(_vm.campaign.type === 'coring' && null !== _vm.campaign.startDate)?_c('v-chip',{attrs:{"small":""}},[_c('b',[_vm._v(_vm._s(_vm.countAndDisplayCampaignDate))])]):_vm._e(),(_vm.campaign.type === 'auscultation' && null !== _vm.campaign.endDate)?_c('v-chip',{attrs:{"small":""}},[_c('b',[_vm._v(_vm._s(_vm.moment(_vm.campaign.endDate).format("Do/MM/YYYY")))])]):_vm._e(),('coring' === _vm.campaign.type)?_c('v-chip',{attrs:{"small":""}},[_c('b',[_vm._v(_vm._s(_vm.manageCoresCount))])]):_vm._e(),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNewChipDisplayed),expression:"isNewChipDisplayed"}],attrs:{"small":"","color":"blue","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t("new"))+" ")]),(_vm.campaign.endDate !== null && _vm.isToLate)?_c('v-chip',{attrs:{"small":"","color":"red","outlined":""}},[_c('b',[_vm._v("Date dépassée")])]):(_vm.campaign.endDate !== null && _vm.isUrgent)?_c('v-chip',{attrs:{"small":"","color":"red","outlined":""}},[_vm._v(" Urgent ")]):_vm._e(),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.campaign.usersLinkedCount === 0),expression:"campaign.usersLinkedCount === 0"}],attrs:{"small":"","color":"red","outlined":""}},[_vm._v(" Equipe projet non renseignée ")]),(_vm.campaign.archived)?_c('v-chip',{attrs:{"small":"","color":"red","outlined":""}},[_vm._v(" Archivé ")]):_vm._e()],1)]),_c('div',{staticClass:"tw-flex tw-items-start tw-gap-[12px] tw-h-full tw-cursor-default"},[(_vm.editMode === false)?_c('div',{staticClass:"tw-flex tw-flex-col tw-h-full tw-justify-evenly tw-items-end",on:{"click":function($event){return $event.stopPropagation()}}},[_c('div',{staticClass:"tw-w-[112px] lg:tw-w-auto tw-gap-[12px] tw-flex tw-flex-wrap lg:tw-flex-nowrap"},_vm._l((_vm.listStatus),function(ref){
			var index = ref[0];
			var value = ref[1];
return _c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"tw-w-[50px] tw-h-[15px] tw-rounded-[4px] tw-flex justify-center",class:{
							'tw-bg-[#ebebeb]': _vm.currentStatusInfo.step < value.step && _vm.currentStatusInfo.step !== _vm.lastStep,
							'tw-bg-[#ff9c42]': _vm.currentStatusInfo.step > value.step && _vm.currentStatusInfo.step !== _vm.lastStep,
							'tw-bg-[#ed7507]': _vm.currentStatusInfo.step === value.step && _vm.currentStatusInfo.step !== _vm.lastStep,
							'tw-bg-[green]': _vm.currentStatusInfo.step === _vm.lastStep
						}},'span',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(value.label)+" ")])])}),1),_c('span',{staticClass:"tw-self-start"},[_vm._v(" "+_vm._s(_vm.$t(_vm.campaign.status))+" ")])]):_c('div',{staticClass:"tw-w-[200px] tw-grid tw-grid-cols-2 tw-gap-x-[5px] tw-gap-y-[2px]",on:{"click":function($event){return $event.stopPropagation()}}},[_c('v-select',{staticClass:"tw-col-span-2",attrs:{"dense":"","items":_vm.listStatus,"label":"Status","outlined":"","item-text":function (value) { return value[1].label; },"item-value":function (value) { return value[0]; },"hide-details":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('ButtonSlot',{staticClass:"tw-col-span-1",attrs:{"_icon":"mdi-check","_small":""},on:{"click":_vm.updateStatus}}),_c('ButtonSlot',{staticClass:"tw-col-span-1",attrs:{"_theme":"light-gray","_icon":"mdi-close","_small":""},on:{"click":function($event){$event.stopPropagation(); _vm.editMode = false}}})],1),_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-between tw-h-full"},[(_vm.$hasRight('campaigns.editCampaignBtn'))?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('ButtonSlot',_vm._g(_vm._b({attrs:{"_icon":"mdi-dots-horizontal","_icon-size":"25px","_theme":"none","_full-rounded":""},on:{"click":function($event){return $event.stopPropagation();}}},'ButtonSlot',attrs,false),on))]}}],null,false,3403655102)},[_c('v-list',{staticClass:"pa-0",attrs:{"width":"25vh"}},[_c('v-list-item',{attrs:{"to":{ name: 'manage-campaign', params: { id: _vm.campaign.id } }}},[_c('v-list-item-title',[_vm._v("Éditer")])],1),(_vm.campaign.type === 'auscultation')?_c('v-list-item',{on:{"click":function($event){_vm.editMode = true}}},[_c('v-list-item-title',[_vm._v("Modifier le statut")])],1):_vm._e(),(
						_vm.$hasRight('campaigns.editCampaignBtn') &&
							_vm.campaign.status === 'taking_validated'
					)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();_vm.campaign.status === 'awaiting_validation' ||
							_vm.campaign.status === 'programing_validated'
							? function () {}
							: _vm.redirectToPrestationSettings()}}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({on:{"click":function () {}}},'v-list-item-title',attrs,false),on),[_vm._v(" Paramétrer les prestations ")])]}}],null,false,2818652049)},[_c('span',[_vm._v("Paramétrage couche par couche des prestations")])])],1):_vm._e(),(!_vm.campaign.archived)?_c('v-list-item',{on:{"click":function($event){return _vm.archiveCampaign({ isArchived: true })}}},[_c('v-list-item-title',[_vm._v("Archiver")])],1):_vm._e(),(_vm.campaign.archived)?_c('v-list-item',{on:{"click":function($event){return _vm.archiveCampaign({ isArchived: false })}}},[_c('v-list-item-title',[_vm._v("Désarchiver")])],1):_vm._e(),(_vm.$hasRight('globalActions.deleteCampaignsAndProjects'))?_c('v-list-item',{on:{"click":function($event){_vm.deleteDialog = true}}},[_c('v-list-item-title',{staticClass:"red-text"},[_vm._v(" Supprimer ")])],1):_vm._e()],1)],1):_vm._e(),(_vm.campaign.status === 'sent_for_analysis' && _vm.$hasRight('campaigns.createCampaignBtn'))?_c('ButtonSlot',{attrs:{"_icon":"mdi-sync","_icon-size":"25px","_theme":"none","_small":""},on:{"click":function($event){$event.stopPropagation();return _vm.refreshData.apply(null, arguments)}}}):_vm._e()],1)]),(_vm.deleteDialog)?_c('DeleteCampaignProjetPopup',{attrs:{"campaignOrProjectId":_vm.campaign.id,"type":"campaign"},on:{"closeModale":function($event){return _vm.deleteCampaign()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }