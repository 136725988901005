export const infoStatusAuscultation = Object.freeze({
	preparing: {
		label: "Préparation",
		step: 1
	},

	measuring: {
		label: "Analyses",
		step: 2
	},

	processing: {
		label: "Traitement",
		step: 3
	},

	engineering: {
		label: "Expertise",
		step: 4
	},

	finishing: {
		label: "Finalisation",
		step: 5
	},

	finished: {
		label: "Terminé",
		step: 6
	},

	archived: {
		label: "Archivé",
		step: 7
	}
});
