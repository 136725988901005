<template>
	<div
	class="tw-flex tw-justify-between tw-p-[10px] tw-items-start tw-cursor-pointer"
	campaign-card
	@click="redirectToCampaignView"
	>
		<div class="tw-flex tw-flex-col tw-gap-[10px] tw-overflow-hidden tw-h-full tw-justify-between">
			<div class="tw-flex tw-flex-row">
				<h2
				class="majFL tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden lineHeight tw-mr-[5px]"
				>
					{{ campaign.name }}
				</h2>

				<p
				class="business"
				v-if="campaign.workspace.site.organization.id='6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' && campaign.type === 'coring'"
				>
					{{ campaign.business }}
				</p>
			</div>

			<div class="tw-flex tw-gap-[10px] tw-flex-wrap">
				<v-chip
				v-if="campaign.type === 'coring'"
				small
				>
					<b class="majFL">
						{{ $t(campaign.coringType) }}
					</b>
				</v-chip>

				<v-chip
				v-else
				small
				>
					<b>{{ $t(campaign.type) }}</b>
				</v-chip>

				<v-chip
				small
				v-if="
					campaign.type === 'auscultation' &&
						campaign.operatingMode !== 'not_standard' &&
						campaign.operatingMode !== null
				"
				>
					<b>{{ $t(campaign.operatingMode) }}</b>
				</v-chip>

				<v-chip
				v-if="campaign.type === 'coring' && null !== campaign.startDate"
				small
				>
					<b>{{ countAndDisplayCampaignDate }}</b>
				</v-chip>

				<v-chip
				v-if="campaign.type === 'auscultation' && null !== campaign.endDate"
				small
				>
					<b>{{ moment(campaign.endDate).format("Do/MM/YYYY") }}</b>
				</v-chip>

				<v-chip
				v-if="'coring' === campaign.type"
				small
				>
					<b>{{ manageCoresCount }}</b>
				</v-chip>

				<v-chip
				small
				v-show="isNewChipDisplayed"
				color="blue"
				outlined
				>
					{{ $t("new") }}
				</v-chip>

				<v-chip
				v-if="campaign.endDate !== null && isToLate"
				small
				color="red"
				outlined
				>
					<b>Date dépassée</b>
				</v-chip>

				<v-chip
				small
				v-else-if="campaign.endDate !== null && isUrgent"
				color="red"
				outlined
				>
					Urgent
				</v-chip>

				<v-chip
				small
				v-show="campaign.usersLinkedCount === 0"
				color="red"
				outlined
				>
					Equipe projet non renseignée
				</v-chip>

				<v-chip
				small
				v-if="campaign.archived"
				color="red"
				outlined
				>
					Archivé
				</v-chip>
			</div>
		</div>

		<div class="tw-flex tw-items-start tw-gap-[12px] tw-h-full tw-cursor-default">
			<div
			@click="$event.stopPropagation()"
			v-if="editMode === false"
			class="tw-flex tw-flex-col tw-h-full tw-justify-evenly tw-items-end"
			>
				<div class="tw-w-[112px] lg:tw-w-auto tw-gap-[12px] tw-flex tw-flex-wrap lg:tw-flex-nowrap">
					<v-tooltip
					v-for="[index, value] of listStatus"
					top
					>
						<template v-slot:activator="{ on, attrs }">
							<span
							class="tw-w-[50px] tw-h-[15px] tw-rounded-[4px] tw-flex justify-center"
							:class="{
								'tw-bg-[#ebebeb]': currentStatusInfo.step < value.step && currentStatusInfo.step !== lastStep,
								'tw-bg-[#ff9c42]': currentStatusInfo.step > value.step && currentStatusInfo.step !== lastStep,
								'tw-bg-[#ed7507]': currentStatusInfo.step === value.step && currentStatusInfo.step !== lastStep,
								'tw-bg-[green]': currentStatusInfo.step === lastStep
							}"
							v-bind="attrs"
							v-on="on"
							/>
						</template>

						<span>
							{{ value.label }}
						</span>
					</v-tooltip>
				</div>

				<span class="tw-self-start">
					{{ $t(campaign.status) }}
				</span>
			</div>

			<div
			@click="$event.stopPropagation()"
			class="tw-w-[200px] tw-grid tw-grid-cols-2 tw-gap-x-[5px] tw-gap-y-[2px]"
			v-else
			>
				<v-select
				class="tw-col-span-2"
				dense
				:items="listStatus"
				label="Status"
				outlined
				:item-text="(value) => value[1].label"
				:item-value="(value) => value[0]"
				v-model="status"
				hide-details
				/>

				<ButtonSlot
				@click="updateStatus"
				_icon="mdi-check"
				_small
				class="tw-col-span-1"
				/>

				<ButtonSlot
				_theme="light-gray"
				_icon="mdi-close"
				_small
				class="tw-col-span-1"
				@click="$event.stopPropagation(); editMode = false"
				/>
			</div>

			<div class="tw-flex tw-flex-col tw-justify-between tw-h-full">
				<v-menu v-if="$hasRight('campaigns.editCampaignBtn')">
					<template v-slot:activator="{ on, attrs }">
						<ButtonSlot
						_icon="mdi-dots-horizontal"
						_icon-size="25px"
						_theme="none"
						_full-rounded
						v-bind="attrs"
						v-on="on"
						@click="$event.stopPropagation();"
						/>
					</template>

					<v-list
					class="pa-0"
					width="25vh"
					>
						<v-list-item
						:to="{ name: 'manage-campaign', params: { id: campaign.id } }"
						>
							<v-list-item-title>Éditer</v-list-item-title>
						</v-list-item>

						<v-list-item
						v-if="campaign.type === 'auscultation'"
						@click="editMode = true"
						>
							<v-list-item-title>Modifier le statut</v-list-item-title>
						</v-list-item>

						<v-list-item
						@click.stop="
							campaign.status === 'awaiting_validation' ||
								campaign.status === 'programing_validated'
								? () => {}
								: redirectToPrestationSettings()
						"
						v-if="
							$hasRight('campaigns.editCampaignBtn') &&
								campaign.status === 'taking_validated'
						"
						>
							<v-tooltip left>
								<template v-slot:activator="{ on, attrs }">
									<v-list-item-title
									@click="() => {}"
									v-on="on"
									v-bind="attrs"
									>
										Paramétrer les prestations
									</v-list-item-title>
								</template>

								<span>Paramétrage couche par couche des prestations</span>
							</v-tooltip>
						</v-list-item>

						<v-list-item
						@click="archiveCampaign({ isArchived: true })"
						v-if="!campaign.archived"
						>
							<v-list-item-title>Archiver</v-list-item-title>
						</v-list-item>

						<v-list-item
						@click="archiveCampaign({ isArchived: false })"
						v-if="campaign.archived"
						>
							<v-list-item-title>Désarchiver</v-list-item-title>
						</v-list-item>

						<v-list-item
						@click="deleteDialog = true"
						v-if="$hasRight('globalActions.deleteCampaignsAndProjects')"
						>
							<v-list-item-title class="red-text">
								Supprimer
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>

				<ButtonSlot
				_icon="mdi-sync"
				_icon-size="25px"
				_theme="none"
				v-if="campaign.status === 'sent_for_analysis' && $hasRight('campaigns.createCampaignBtn')"
				@click.stop="refreshData"
				_small
				/>
			</div>
		</div>

		<DeleteCampaignProjetPopup
		v-if="deleteDialog"
		@closeModale="deleteCampaign()"
		:campaignOrProjectId="campaign.id"
		type="campaign"
		/>
	</div>
</template>

<script>
import CampaignStepBar from "./CampaignStepBar.vue";
import {infoStatusAuscultation} from "../../utils/auscultation";
import {infoStatusCoringCA, infoStatusCoringCS} from "../../utils/coring";
import DeleteCampaignProjetPopup from "../popup/DeleteCampaignProjet.popup.vue";
import {mapActions} from "vuex";

export default {
	name: "CampaignCard",
	components: {CampaignStepBar, DeleteCampaignProjetPopup},
	props: {
		campaign: {
			type: Object,
			required: true
		}
	},
	data(){
		return {
			editMode: false,
			status: false,
			deleteDialog: false
		};
	},
	computed: {
		countAndDisplayCampaignDate(){
			let daysCounter = this.moment(this.campaign.endDate).diff(this.campaign.startDate, "days") + 1;
			let dayOrDays = daysCounter > 1 ? `${daysCounter} jours` : `${daysCounter} jour`;
			let campaignDateDisplay = `${this.moment(this.campaign.startDate).format(
				"Do/MM/YYYY"
			)} - ${this.moment(this.campaign.endDate).format(
				"Do/MM/YYYY"
			)} (${dayOrDays})`;

			return campaignDateDisplay;
		},

		manageCoresCount(){
			let message = `${this.campaign.coresCount} `;

			const coresTaken = [
				"taken",
				"taking_validated",
				"sent_for_analysis",
				"results_obtained",
				"status_archived"
			];

			if(0 < this.campaign.coresCount && !coresTaken.includes(this.campaign.status)){
				message += 1 === this.campaign.coresCount ? "carotte implantée" : "carottes implantées";
			} 
			else if(0 < this.campaign.coresCount && coresTaken.includes(this.campaign.status)){
				message += 1 === this.campaign.coresCount ? "carotte réalisée" : "carottes réalisées";
			} 
			else {
				message = "Carottes non implantées";
			}

			return message;
		},

		listStatus(){
			if(this.campaign.type == "auscultation") return Object.entries(infoStatusAuscultation).slice(0, -1);
			else if(this.campaign.type == "coring" && this.campaign.coringType === "CA") return Object.entries(infoStatusCoringCA).slice(0, -1);
			else if(this.campaign.type == "coring" && this.campaign.coringType === "CS") return Object.entries(infoStatusCoringCS).slice(0, -1);
			else return [];
		},

		currentStatusInfo(){
			if(this.campaign.type == "auscultation") return infoStatusAuscultation[this.campaign.status];
			else if(this.campaign.type == "coring" && this.campaign.coringType === "CA") return infoStatusCoringCA[this.campaign.status];
			else if(this.campaign.type == "coring" && this.campaign.coringType === "CS") return infoStatusCoringCS[this.campaign.status];
			else return {};
		},

		lastStep(){
			if(this.campaign.type == "auscultation") return Object.keys(infoStatusAuscultation).length - 1;
			else if(this.campaign.type == "coring" && this.campaign.coringType === "CA") return Object.keys(infoStatusCoringCA).length - 1;
			else if(this.campaign.type == "coring" && this.campaign.coringType === "CS") return Object.keys(infoStatusCoringCS).length - 1;
			else return 0;
		},

		isNewChipDisplayed(){
			let oneWeekAgo = new Date();
			oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
			return Date.parse(this.campaign.createdAt) > oneWeekAgo;
		}, 

		isUrgent(){
			let oneWeekAgo = new Date(this.campaign.endDate);
			oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
			return oneWeekAgo.getTime() < Date.now() && this.currentStatusInfo.step < this.lastStep;
		},

		isToLate(){
			return Date.parse(this.campaign.endDate) < Date.now() && this.currentStatusInfo.step < this.lastStep; 
		}
	},
	watch: {
		editMode(){
			if(this.editMode === true) this.status = this.campaign.status;
			else this.status = false;
		}
	},
	methods: {
		...mapActions("project", [
			"initStore", "purgeStore", "updateCampaign"
		]),

		archiveCampaign(archived){
			const action = archived.isArchived === true ? "archiver" : "désarchiver";
			this.$toast(
				"info",
				"Êtes vous sûr de vouloir " + action + " la campagne ?",
				action,
				async() => {
					const campaign = await this.$api.campaigns.editArchived(
						this.campaign.id,
						archived.isArchived
					);

					await this.updateCampaign(this.campaign.id);
				}
			);
		},
		redirectToCampaignView(){
			if(this.campaign.type === "auscultation"){
				this.$router.push({
					name: "auscultation-campaign-dashboard",
					params: {id: this.campaign.id}
				});
			} 
			else {
				let routeName = "coring-campaign";
				if(!this.$hasRight("campaigns.accessPrepareCampaign")){
					if(this.campaign.coringType === "CA"){
						routeName += "-dashboard";
					}

					if(this.campaign.coringType === "CS"){
						routeName = "structural-" + routeName + "-dashboard";
					}	
				} 
				else {
					if(this.campaign.status === "results_obtained" && this.campaign.coringType === "CA"){
						routeName += "-dashboard";
					
					}
					if(this.campaign.status === "results_obtained" && this.campaign.coringType === "CS"){
						routeName = "structural-" + routeName + "-dashboard";
					}	
				}

				this.$router.push({
					name: routeName,
					params: {id: this.campaign.id}
				});
			}
		},

		async refreshData(){
			await this.$api.coringCampaigns.getFromProvider(this.campaign.id);
			this.updateCampaign(this.campaign.id);
		},

		redirectToPrestationSettings(){
			this.$router.push({
				name: "coring-campaign-prestations",
				params: {id: this.campaign.id}
			});
		},

		async updateStatus(){
			await this.$api.auscultationCampaigns.editStatus(this.campaign.id, this.status);
			this.editMode = false;
			this.initStore(this.$route.params.id);
		},

		deleteCampaign(){
			this.deleteDialog = false;
		}
	},
	mounted(){
		
	}
};
</script>

<style lang="scss">
.business {
	color: #444444;
	font-size: 12px;
	margin-bottom: 0 !important;
	align-self: last baseline ;
}

div[campaign-card]{
    user-select: none;
    border-radius: 4px;
    border: solid rgba(0,0,0,0.12) 1px;
	
	.majFL{
		&::first-letter{
			text-transform: uppercase;
		}
	}

	[tooltip]{
		position: relative;

		&::before{
			content: attr(tooltip);
			position: absolute;
			transform: translateY(calc(-100% - 5px));
			background: rgb(128, 128, 128);
			z-index: 4;
			font-size: 12px;
			padding: 4px;
			border-radius: 4px;
			scale: 0;
			transition: scale 200ms ease-in-out;
			color: #fff;
		}

		&:hover::before{
			scale: 1;
		}
	}
}
	.red-text {
		color: red
	}
	.lineHeight {
		line-height:normal;
	align-self: last baseline ;
	}
</style>
